export const timezones = [
  {
    label: 'America/Sao_Paulo',
    value: 'America/Sao_Paulo',
  },
  {
    label: 'Brazil/Acre',
    value: 'Brazil/Acre',
  },
  {
    label: 'Brazil/DeNoronha',
    value: 'Brazil/DeNoronha',
  },
  {
    label: 'America/Campo_Grande',
    value: 'America/Campo_Grande',
  },
];
