import { GridRowParams } from '@material-ui/data-grid';
import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';

import FormDefault from '~/components/FormDefault';
import Search from '~/components/Search';

import { Container } from './styles';

import { Table, Form } from './components';
import { useUsuario } from './UsuarioContext';
import { validaEmail } from '~/utils/functions';
import { Loja, RequestCreateUser, SubmitFormProps } from './protocols';
import DefaultLoader from '~/components/DefaultLoader';
import apiUsuario from './services';
import { LojaContext } from '~/context/loja';
import useAuth from '~/hooks/useAuth';

export const UsuarioContent: React.FC = () => {
  const [showSearch, setShowSearch] = useState(true);
  const { changeLoja } = useContext(LojaContext);
  const user = useAuth();

  const {
    loader,
    isUpdate,
    handleChangeIsUpdate,
    formUsuario,
    handleClearFormUsuario,
    getUsuario,
    handleFormDisabled,
    sendEmailValidateUser,
    codUsuario,
    setCodUsuario,
    handleSubmit,
    setValue,
    getValues,
  } = useUsuario();

  const prepareBodyRequest = (data: SubmitFormProps): RequestCreateUser => {
    const requestBody: RequestCreateUser = {
      des_usuario: data.des_usuario,
      email: String(data.email).toLowerCase(),
      password: data.password,
      timezone: data.timezone.value,
      cod_controle: data.cod_controle.value,
      lojas: data.lojas,
      flg_inativo: data.flg_inativo,
      flg_admin: data.flg_admin,
      flg_superadmin: data.flg_superadmin,
    };
    return requestBody;
  };

  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;

    setCodUsuario(row.cod_usuario);
    setValue('cod_usuario', row.cod_usuario);
    getUsuario(row.cod_usuario);

    handleFormDisabled(false);
    setShowSearch(false);
    handleChangeIsUpdate(true);
  };

  const onCancel = () => {
    handleClearFormUsuario();
    handleChangeIsUpdate(false);
    setShowSearch(true);
  };

  const onDelete = async () => {
    const { cod_usuario, flg_inativo } = getValues();
    if (flg_inativo) return toast.warning('Usuário já inativo');

    const resultRequest = await apiUsuario.delete(cod_usuario);
    if (!resultRequest.success) {
      toast.warn(resultRequest.message);
      return;
    }
    toast.success(resultRequest.message);
    onCancel();
  };

  const onNew = () => {
    handleClearFormUsuario();
    handleChangeIsUpdate(false);
    setShowSearch(false);
  };

  const onSave = handleSubmit(async (data: SubmitFormProps) => {
    const emailIsValid = validaEmail(data.email);
    if (!emailIsValid) return toast.warn('O Email informado é inválido!');

    if (!data.lojas && !formUsuario.flg_inativo.value)
      return toast.warn('É necessário vincular ao menos uma loja ao usuário.');

    const hasPadrao = data.lojas.find((loja: Loja) => {
      return loja.flg_padrao;
    });

    if (!hasPadrao && !formUsuario.flg_inativo.value)
      return toast.warn('Defina uma loja padrão para o usuário.');

    const requestBody: RequestCreateUser = prepareBodyRequest(data);

    if (!isUpdate) {
      await apiUsuario.create(requestBody);

      const sendMail = await sendEmailValidateUser();
      handleClearFormUsuario();

      if (!sendMail.success) toast.warning(sendMail.message);

      return toast.success(
        <span>
          Usuário cadastrado com sucesso <br /> {sendMail.message}
        </span>,
      );
    }

    const cod_usuario = getValues('cod_usuario');
    const resultRequest = await apiUsuario.update(requestBody, cod_usuario);

    const { success, message } = resultRequest;

    if (!success) throw new Error(message);

    if (user.user.id === codUsuario) {
      if (requestBody.lojas) {
        const lojaPadrao = requestBody.lojas.find((loj: any) => loj.flg_padrao);

        if (lojaPadrao) {
          changeLoja(lojaPadrao.cod_loja);
        }
      }
    }

    onCancel();

    return toast.success(message);
  });

  if (loader) {
    return <DefaultLoader />;
  }

  return (
    <Container>
      {showSearch && (
        <Search codTela={2} newData={onNew} onRowClick={onRowClick} />
      )}
      {!showSearch && (
        <FormDefault
          title="Cadastro de Usuário"
          codigoRegistro={[{ value: codUsuario, des_campo: 'Código' }]}
          codTela={2}
          onSave={onSave}
          onCancel={onCancel}
          isUpdate={isUpdate}
          onNew={onNew}
          onDelete={onDelete}
          onClearFields={handleClearFormUsuario}
          onReturnSearch={onCancel}
          labelButtonTrash="Inativar"
          deleteMessage="Deseja realmente inativar o registro?"
          isDeactivateDeleteMessage={formUsuario.flg_inativo.value}
        >
          <Form />
          <Table />
        </FormDefault>
      )}
    </Container>
  );
};
