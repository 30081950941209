import api from '~/services/api';
import { RequestCreateUser, Response } from '../protocols';

const apiUsuario = {
  create: async (requestData: RequestCreateUser): Promise<Response> => {
    const request = await api.post('/usuario', {
      usuario: { ...requestData },
    });
    const { success, message } = request.data;

    return {
      success,
      message,
    };
  },
  update: async (
    requestData: RequestCreateUser,
    codUsuario: number | undefined,
  ): Promise<Response> => {
    const request = await api.put(`/usuario/${codUsuario}`, {
      usuario: { ...requestData },
    });
    const { success, message } = request.data;

    return {
      success,
      message,
    };
  },
  delete: async (codUsuario: number | undefined): Promise<Response> => {
    const request = await api.delete(`/usuarios/${codUsuario}`);
    const { success, message } = request.data;

    return {
      success,
      message,
    };
  },
  getLojas: async (codUsuario: number): Promise<Response> => {
    const request = await api.get(`/usuario/lojas/${codUsuario}`);
    const { success, message, data } = request.data;

    return { success, message, data };
  },
  getUsuario: async (codUsuario: number | undefined): Promise<Response> => {
    const request = await api.get(`/usuario/${codUsuario}`);
    const { success, message, data } = request.data;

    return { success, message, data };
  },
  getAcessos: async (): Promise<Response> => {
    const request = await api.get('/controle-acesso');
    const { success, message, data } = request.data;

    return { success, message, data };
  },
  sendEmail: async (email: string | undefined): Promise<Response> => {
    const request = await api.post('/usuarios/sendEmailValidateUser', {
      email,
    });
    const { success, message } = request.data;

    return { success, message };
  },
};

export default apiUsuario;
