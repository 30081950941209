import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
`;

export const BtnIcon = styled.button`
  background-color: #4c9aff;
  border: none;
  padding: 3px 12px;
  border-radius: 4px;
  color: #fff;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  height: 40px;

  &:hover {
    opacity: 0.7;
  }
`;
