import styled from 'styled-components';

export const FieldIcon = styled.div`
  position: relative;

  input {
    width: 100%;
    padding-right: '2.5rem';
  }
`;

export const BtnIcon = styled.button`
  position: relative;
  background-color: #4c9aff;
  border: none;
  padding: 3px 12px;
  border-radius: 4px;
  color: #fff;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  height: 40px;
  overflow: visible;

  &:hover {
    opacity: 0.7;
  }

  &:disabled {
    opacity: 0.5;
    cursor: no-drop;
  }

  &:before {
    content: 'Enviar Convite';
    position: absolute;
    background: #2b78e4;
    color: #fff;
    padding: 0.3125rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    white-space: nowrap;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s;
    top: -1.875rem;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 0.5rem;
  }

  &:hover:before {
    visibility: visible;
    opacity: 1;
  }

  &:disabled:hover:before {
    visibility: hidden;
    opacity: 0;
  }
`;
