import React from 'react';
import { UsuarioContent } from './UsuarioContent';
import { UsuarioContextProvider } from './UsuarioContext';

const Usuarios: React.FC = () => {
  return (
    <UsuarioContextProvider>
      <UsuarioContent />
    </UsuarioContextProvider>
  );
};

export default Usuarios;
