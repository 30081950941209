import * as yup from 'yup';

export const schema = yup
  .object({
    des_usuario: yup.string().required(),
    email: yup.string().email().required(),
    timezone: yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required(),
    }),
    cod_controle: yup
      .mixed()
      .when('acesso_required', {
        is: (value: any) => {
          if (value) return true;
          return false;
        },
        then: yup.object().shape({
          label: yup.string().notRequired().nullable(true),
          value: yup.number().notRequired().nullable(true),
        }),
        otherwise: yup.object().shape({
          label: yup.string().required(),
          value: yup.number().required(),
        }),
      })
      .default(null),
    password: yup.string().when('isUpdate', {
      is: (value: any) => {
        if (value) return true;
        return false;
      },
      then: yup.string().notRequired(),
      otherwise: yup.string().required(),
    }),
  })
  .required();
